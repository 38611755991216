var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.template, {
    tag: "component"
  }, [_c('v-responsive', {
    staticClass: "member-orders py-9 px-0 px-md-3"
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('span', {
    staticClass: "headline mb-8"
  }, [_vm._v("상품권 주문내역")])]), _c('v-divider', {
    staticClass: "mt-6"
  }), _c('v-data-table', {
    attrs: {
      "items": _vm.orders,
      "headers": _vm.ordersHeaders,
      "mobile-breakpoint": -1,
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.purchases",
      fn: function (_ref) {
        var item = _ref.item;
        return _c('v-responsive', {
          attrs: {
            "min-width": "200"
          }
        }, _vm._l(item.purchases, function (purchase) {
          var _purchase$thumb, _purchase$thumb2;
          return _c('v-layout', {
            key: purchase._id,
            attrs: {
              "align-center": ""
            }
          }, [_c('v-img', {
            attrs: {
              "src": (purchase === null || purchase === void 0 ? void 0 : (_purchase$thumb = purchase.thumb) === null || _purchase$thumb === void 0 ? void 0 : _purchase$thumb.path) || (purchase === null || purchase === void 0 ? void 0 : (_purchase$thumb2 = purchase.thumb) === null || _purchase$thumb2 === void 0 ? void 0 : _purchase$thumb2.src),
              "max-width": "48",
              "max-height": "48"
            }
          }), _c('span', {
            staticClass: "mx-2"
          }, [_c('b', [_vm._v(_vm._s(purchase === null || purchase === void 0 ? void 0 : purchase.name))])])], 1);
        }), 1);
      }
    }, {
      key: "item.status",
      fn: function (_ref2) {
        var item = _ref2.item;
        return _vm._l(item.purchases, function (purchase) {
          return _c('v-layout', {
            key: purchase._id,
            attrs: {
              "justify-center": ""
            }
          }, [_c('span', [_vm._v(_vm._s(purchase === null || purchase === void 0 ? void 0 : purchase.status))])]);
        });
      }
    }, {
      key: "item.paymentStatus",
      fn: function (_ref3) {
        var item = _ref3.item;
        return _vm._l(item.purchases, function (purchase) {
          return _c('v-layout', {
            key: purchase._id,
            attrs: {
              "justify-center": ""
            }
          }, [_c('span', [_vm._v(_vm._s(purchase === null || purchase === void 0 ? void 0 : purchase.paymentStatus))])]);
        });
      }
    }, {
      key: "item.totalAmount",
      fn: function (_ref4) {
        var item = _ref4.item;
        return _c('span', {}, [_vm._v(_vm._s(item.totalAmount.format()) + " 원")]);
      }
    }, {
      key: "item.createdAt",
      fn: function (_ref5) {
        var item = _ref5.item;
        return _c('span', {}, [_vm._v(_vm._s(_vm.$dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss")))]);
      }
    }, {
      key: "item.resend",
      fn: function (_ref6) {
        var _item$issue, _item$issue$pins;
        var item = _ref6.item;
        return _c('span', {}, [(_item$issue = item.issue) !== null && _item$issue !== void 0 && (_item$issue$pins = _item$issue.pins) !== null && _item$issue$pins !== void 0 && _item$issue$pins.length ? _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.resend(item);
            }
          }
        }, [_vm._v("mdi-message")]) : _vm._e()], 1);
      }
    }])
  }), _c('v-divider'), _c('v-pagination', {
    staticClass: "mt-6",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "11"
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }